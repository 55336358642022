<template>
  <div class="personal-center-index">
    <div class="num-grid-box">
      <div style="background: #7cacff">
        <img src="../../../assets/image/finish-icon.png" style="width: 60px; height: 60px; margin-right: 20px" />
        <div style="width: 180px; display: flex; flex-direction: column; line-height: 30px; font-size: 16px">
          <div style="display: flex">
            <div style="width: 120px">任务进度</div>
            <div>6</div>
          </div>
          <div style="display: flex">
            <div style="width: 120px">已完成任务</div>
            <div>4</div>
          </div>
        </div>
        <div style="flex: 1; font-size: 24px; padding-left: 20px; line-height: 60px; border-left: 1px solid #fff">进度：67%</div>
      </div>
      <div style="background: #ac7eff">
        <img src="../../../assets/image/score-icon.png" style="width: 60px; height: 60px; margin-right: 20px" />
        <div style="width: 180px; display: flex; flex-direction: column; line-height: 30px; font-size: 16px">
          <div style="display: flex">
            <div style="width: 120px">班级平均分</div>
            <div>93</div>
          </div>
          <div style="display: flex">
            <div style="width: 120px">个人平均分</div>
            <div>94</div>
          </div>
        </div>
        <div style="flex: 1; font-size: 24px; padding-left: 20px; line-height: 60px; border-left: 1px solid #fff">排名：3</div>
      </div>
      <div style="background: #64dbb5">
        <img src="../../../assets/image/no-finish-icon.png" style="width: 60px; height: 60px; margin-right: 20px" />
        <div style="width: 180px; line-height: 60px; font-size: 16px">待完成任务</div>
        <div style="flex: 1; font-size: 24px; padding-left: 20px; line-height: 60px; border-left: 1px solid #fff">数量：2</div>
      </div>
    </div>
    <div class="table-grid-box">
      <div>
        <div id="echarts" style="width: 100%; height: 100%"></div>
      </div>
      <div>
        <el-select v-model="sortType" style="margin-bottom: 20px">
          <el-option v-for="(item, index) in sortTypeArr" :key="item" :label="item" :value="index"></el-option>
        </el-select>
        <div v-for="item in scoreTypeTable[sortType % 2]" style="height: 40px; display: flex; align-items: center; padding: 0 30px">
          <div style="width: 60px">{{ item.name }}</div>
          <el-progress :percentage="item.score" color="#ac7eff" style="flex: 1; margin-right: 12px" :show-text="false"></el-progress>
          <div style="width: 60px">{{ item.score }}分</div>
        </div>
      </div>
    </div>
    <div class="table-grid-box" style="margin-bottom: 0">
      <div>
        <el-table :data="tableList" style="width: 100%" :header-cell-style="{ background: '#f6f6f6', color: '#35323B', fontWeight: 400 }">
          <el-table-column prop="index" label="序号"></el-table-column>
          <el-table-column prop="name" label="任务名称"></el-table-column>
          <el-table-column prop="progress" label="班级完成度" width="200">
            <template slot-scope="scope">
              <el-progress :percentage="scope.row.progress" color="#64dbb5"></el-progress>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div style="margin-bottom: 20px; font-size: 16px">班级平均分排行</div>
        <div v-for="item in classScoreTable" style="height: 40px; display: flex; align-items: center; padding: 0 30px">
          <div style="width: 60px">{{ item.name }}</div>
          <el-progress :percentage="item.score" color="#7cacff" style="flex: 1; margin-right: 12px" :show-text="false"></el-progress>
          <div style="width: 60px">{{ item.score }}分</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ECharts from "echarts";

export default {
  name: "Index",
  components: {},
  props: {},
  data() {
    return {
      sortType: 0,
      sortTypeArr: ["市场分析成绩排名", "直播售卖成绩排名", "创建直播成绩排名", "直播推广成绩排名", "优惠券管理成绩排名"],
      scoreTypeTable: {
        0: [
          { name: "赵倩", score: 96 },
          { name: "王博研", score: 94 },
          { name: "韩湘龙", score: 92 },
          { name: "李锐", score: 91 },
          { name: "李悠", score: 90 },
          { name: "刘颖超", score: 88 },
          { name: "赵琳", score: 87 },
        ],
        1: [
          { name: "赵倩", score: 97 },
          { name: "王博研", score: 95 },
          { name: "韩湘龙", score: 91 },
          { name: "李悠", score: 87 },
          { name: "刘颖超", score: 86 },
          { name: "李锐", score: 93 },
          { name: "赵琳", score: 0 },
        ],
      },
      tableList: [
        { index: 1, name: "市场分析", progress: 100 },
        { index: 2, name: "直播售卖", progress: 85 },
        { index: 3, name: "创建直播", progress: 69 },
        { index: 4, name: "直播推广", progress: 69 },
        { index: 5, name: "优惠券管理", progress: 84 },
      ],
      classScoreTable: [
        { name: "赵倩", score: 97 },
        { name: "李锐", score: 95 },
        { name: "李悠", score: 92 },
        { name: "王博研", score: 91 },
        { name: "赵琳", score: 87 },
        { name: "韩湘龙", score: 83 },
        { name: "刘颖超", score: 77 },
      ],
      echarts: null,
      options: {
        title: {
          text: "任务完成数(Top10)",
        },
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += "<br/>" + params[i].marker + "任务完成数：" + params[i].value + "次";
            }
            return relVal;
          },
        },
        grid: {
          right: "5%",
          bottom: "3%",
          left: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["赵倩", "李悠", "王博研", "李锐", "韩湘龙", "赵琳", "刘颖超"],
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#86909C",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "任务完成数",
          axisLabel: {
            textStyle: {
              color: "#86909C",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#86909C",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: {
          type: "bar",
          color: "#7CACFF",
          barWidth: 25,
          data: [4, 3, 3, 3, 2, 2, 2],
        },
      },
    };
  },
  computed: {},
  methods: {
    init() {
      const echarts = ECharts.init(document.getElementById("echarts"));
      if (!echarts) return;
      echarts.setOption(this.options);
      this.echarts = echarts;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.personal-center-index {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  .num-grid-box {
    display: grid;
    grid-template-rows: 100px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    > div {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      color: #fff;
    }
  }
  .table-grid-box {
    display: grid;
    grid-template-rows: 400px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 24px 0;
    > div {
      border: 1px solid #e7e7e7;
      padding: 20px;
    }
  }
}
</style>
